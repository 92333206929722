import axios from "axios";
const API_URL = `${process.env.REACT_APP_API_URL}/meets`;
const APP_URL = `${process.env.REACT_APP_API_URL}`
const APP_TZ_URL = `https://api.ipgeolocation.io/timezone?apiKey=${process.env.REACT_APP_TIMEZONE}`
const GMAP_GEO_URL = `https://maps.googleapis.com/maps/api/geocode/json?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`
const GMAP_TZ_URL = `https://maps.googleapis.com/maps/api/timezone/json?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`

const getPastMeets = (headerData,page) => {
  return axios.get(`${API_URL}/past_meets?page=${page}&per_page=10`, headerData);
};
const getMeet = (meetID) => {
  return axios.get(`${API_URL}/${meetID}/meet`);
};
const findMeet=(headerData,meetID)=>{
  return axios.get(`${API_URL}/${meetID}/find_meet`,headerData);
}
const getCurrentMeets = (headerData) => {
  return axios.get(`${API_URL}/current_meets`, headerData)
}
const getUpcomingMeets = (headerData,page) => {
  return axios.get(`${API_URL}/upcoming_meets?page=${page}&per_page=10`, headerData);
};
const getSearchUpcomingMeets = (headerData, searchValue,pageNumber) => {
  return axios.get(`${API_URL}/upcoming_meets?meet_name=${searchValue}&page=${pageNumber}&per_page=10`, headerData);
};
const filterUpcomingMeets = (headerData, bodyData) => {
  return axios.get(`${API_URL}/upcoming_meets?city=${bodyData.city}&state=${bodyData.state}&date=${bodyData.date}&page=${bodyData.page}&per_page=10`, headerData);
};
const filterPastMeets = (headerData, bodyData) => {
  return axios.get(`${API_URL}/past_meets?city=${bodyData.city}&state=${bodyData.state}&date=${bodyData.date}&page=${bodyData.page}&per_page=10`, headerData);
};
const getSearchPastMeets = (headerData, searchValue,pageNumber) => {
  return axios.get(`${API_URL}/past_meets?meet_name=${searchValue}&page=${pageNumber}&per_page=10`, headerData);
};
const getGoverningBody = (headerData) => {
  return axios.get(`${API_URL}/governing_body_list`, headerData)
}
const getMeetTemplate = (headerData, id) => {
  return axios.get(`${API_URL}/template_list?governing_body_id=${id.governing_body_id}&state_id=${id.state_id}`, headerData)
}
const getEventList = (headerData) => {
  return axios.get(`${API_URL}/event_list`, headerData)
}
const getTemplateEventList = (headerData, bodyData) => {
  if (bodyData.field_data){
    var fieldData = bodyData.field_data 
    var fieldDataString = fieldData;
  }else{
    var fieldData = [];
    var fieldDataString = JSON.stringify(fieldData);
  }
  
  
  return axios.get(`${API_URL}/template_event_list?template_id=${bodyData.template_id}&governing_body_id=${bodyData.governing_body_id}&meet_time=${bodyData.meet_time}&time_zone=${bodyData.time_zone}&action_type=create&field_data=${fieldDataString}`, headerData)
}
const createMeet = (headerData, bodyData) => {
  return axios.post(`${API_URL}/create_meet`, bodyData, headerData)
}
const createTemplate = (headerData, bodyData) => {
  return axios.post(`${API_URL}/create_template`, bodyData, headerData)
}
const deleteTemplate = (headerData ,bodyData) => {
  return axios.delete(`${API_URL}/delete_template?id=${bodyData.template_id}`, headerData);
};
const editMeet = (headerData, bodyData, meetid) => {
  return axios.patch(`${API_URL}/${meetid}/update_meet`, bodyData, headerData)
}
const deleteMeet = (headerData, id) => {
  return axios.delete(`${API_URL}/${id}/delete_meet`, headerData)
}
const deleteEvent = (headerData, data) => {
 
  return axios.delete(`${API_URL}/events/${data.event_id}/delete_event`, headerData)
}
const getSingleEventList = (headerData, id) => {
  return axios.get(`${API_URL}/${id}/event_list`, headerData)
}
const updateEvent=(headerData,event_id,heats)=>{
   return axios.patch(`${API_URL}/events/${event_id}/update_event`,{heats:heats},headerData)
}
const getCurrentMeet = (headerData) => {
  return axios.get(`${API_URL}/current_meets`, headerData)
}
const beforeMeetEdit = (headerData, bodyData) => {
  return axios.post(`${API_URL}/template_event_list`, bodyData, headerData)
}
const afterMeetEdit = (headerData, bodyData) => {
  return axios.post(`${API_URL}/template_event_list`, bodyData, headerData)
}
const multiDayMeet = (headerData, day, page, id) => {
  return axios.get(`${API_URL}/${id}/events?page=${page}&per_page=10&day=${day}`, headerData)
}
const printMeet = (headerData,meet_id)=>{
  return axios.get(`${API_URL}/${meet_id}/print`,headerData)
}
const notificationApi = (bodyData, headerData) => {
  return axios.post(`${API_URL}/send_notification`, bodyData, headerData)
}
const meetNotificationTypes =(headerData,meet_id)=>{
  return axios.get(`${APP_URL}/notification_types?meet_id=${meet_id}`,headerData)
}
const eventNotificationTypes =(headerData,event_id)=>{
  return axios.get(`${APP_URL}/notification_types?event_id=${event_id}`,headerData)
}

const getTimeZone_api =(locvalue, domain_name)=>{
  if (domain_name == `${process.env.REACT_APP_GOOGLE_MAPS_API_URL}`) {
    let targetDate = new Date();
		let timestamp = targetDate.getTime()/1000 + targetDate.getTimezoneOffset() * 60;
    timestamp = Math.round(timestamp);
    return axios.get(`${GMAP_TZ_URL}&timestamp=${timestamp}&location=${locvalue}`)
  } else {
    return axios.get(`${APP_TZ_URL}&location=United States,${locvalue}`)
  }
}

const getLocation_api =(addrvalue)=>{
  return axios.get(`${GMAP_GEO_URL}&address=United States,${addrvalue}`)
}

const getEventListByDay = (headerData, id, day) => {
	return axios.get(`${API_URL}/${id}/events?day=${day}`, headerData);
};
const combinedMeetEvent = (headerData, bodyData) => {
	return axios.patch(`${API_URL}/combined_meet_event`, bodyData, headerData);
};


const MeetServices = {
  getPastMeets,
  getUpcomingMeets,
  getGoverningBody,
  getMeetTemplate,
  getEventList,
  getCurrentMeets,
  getTemplateEventList,
  createMeet,
  createTemplate,
  getSingleEventList,
  getSearchUpcomingMeets,
  editMeet,
  deleteTemplate,
  printMeet,
  afterMeetEdit,
  deleteMeet,
  beforeMeetEdit,
  deleteEvent,
  getSearchPastMeets,
  getMeet,
  updateEvent,
  multiDayMeet,
  filterUpcomingMeets,
  filterPastMeets,
  notificationApi,
  findMeet,
  meetNotificationTypes,
  eventNotificationTypes,
  getTimeZone_api,
  getLocation_api,
getEventListByDay,combinedMeetEvent

}

export default MeetServices;
